<template>
    <div class="PickupSku">
        <el-dialog
            title="查询商品基本信息"
            :visible.sync="dialogFormVisible"
            width="75%"
            top="60px"
            @close="close"
            @opened="handleQuery"
        >
            <el-card shadow="never">
                <el-form @submit.native.prevent inline v-model="form" size="small">
                    <el-form-item label="类目">
                        <el-select placeholder="类目" v-model="form.categoryCode" filterable>
                            <el-option label="全部" value="" />
                            <el-option-group v-for="group in meta.categories" :label="group.name" :key="group.id">
                                <el-option
                                    v-for="category in group.sub"
                                    :label="category.name"
                                    :value="category.code"
                                    :key="category.code"
                                />
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="品牌">
                        <el-select placeholder="品牌" v-model="form.brandCode" filterable>
                            <el-option label="全部" value="" />
                            <el-option v-for="bd in meta.brands" :label="bd.name" :value="bd.code" :key="bd.code" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="商品类型">
                        <el-select placeholder="商品类型" v-model="form.type" @change="vaildType">
                            <el-option label="请选择" value="" />
                            <el-option label="标品" :value="0" />
                            <el-option label="非标品" :value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="查询条件">
                        <el-input placeholder="名称/条码" v-model="form.search" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleQuery">查询</el-button>
                    </el-form-item>
                    <!--                    <el-row>-->
                    <!--                        <el-col :span="8"> < </el-col>-->
                    <!--                        <el-col :span="8"> </el-col>-->
                    <!--                        <el-col :span="8"> </el-col>-->
                    <!--                    </el-row>-->
                    <!--                    <el-row>-->
                    <!--                        <el-col :span="8"> </el-col>-->
                    <!--                        <el-col :span="6"> </el-col>-->
                    <!--                    </el-row>-->
                </el-form>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    id="PickupSkuTable"
                    border
                    ref="pickupSkuTable"
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column width="60" type="selection" :selectable="rowCanSelectable" />
                    <el-table-column prop="name" label="名称" min-width="180" />
                    <el-table-column prop="bars" label="条码" width="120">
                        <template slot-scope="scope">
                            <span v-for="b in scope.row.bars" :key="b">{{ b }}<br /></span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="specs" label="规格" width="120" />
                    <el-table-column prop="type" label="类型" width="80">
                        <template slot-scope="scope">
                            <span>{{ scope.row.type ? '非标品' : '标品' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="category" label="类目" width="120" />
                    <el-table-column prop="brand" label="品牌" width="120" />
                    <el-table-column prop="unit" label="单位(小)" width="80" />
                    <el-table-column prop="expirationDate" label="保质期" width="100" />
                    <el-table-column prop="creator" label="创建人" width="120" />
                    <el-table-column prop="createTime" label="创建时间" width="140" />
                </el-table>
                <el-pagination
                    :page-sizes="page.pageSizes"
                    :page-size="form.limit"
                    :layout="page.PageStyle"
                    :total="page.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    @prev-click="handlePrevClick"
                    @next-click="handleNextClick"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="add" size="small">添 加</el-button>
                <el-button type="primary" @click="ok" size="small">添加并关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../js/Util';
import UrlUtils from '../../js/UrlUtils';

export default {
    name: 'PickupSku',
    data() {
        return {
            form: {
                search: '',
                categoryCode: '',
                brandCode: '',
                type: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: [20, 50, 100],
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            lockRows: [],
            url: {
                page: '/goods/sku/pageExtend',
            },
            loading: false,
            dialogFormVisible: false,
            meta: {
                brands: [],
                categories: [],
                flagStand: true,
                flagUnStand: true,
            },
            row: {},
        };
    },
    watch: {
        meta: {
            handler: function (val, oldVal) {},
            deep: true,
        },
    },
    activated() {
        const _this = this;
        UrlUtils.GoodsBrands(_this, (data) => {
            _this.meta.brands = data;
        });
        UrlUtils.GroupedGoodsCategories(_this, (rst) => {
            _this.meta.categories = rst;
        });
    },
    filters: {
        bar(bars) {
            if (bars && bars.length) {
                return bars.join('/');
            }
            return bars;
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, null);
        },
        vaildType() {
            if (this.row.type == 0 || this.row.type == 1) {
                this.form.type = this.row.type;
            }
        },
        //公共接口
        ok() {
            this.add();
            this.dialogFormVisible = false;
        },
        add() {
            const result = this.$refs['pickupSkuTable'].selection.filter((item) => {
                return this.rowCanSelectable(item);
            });
            //点击添加时 将当前选择变为加锁状态
            this.lockRows = Array.from(new Set(result.concat(this.lockRows)));
            this.$emit('ok', result);
        },
        rowCanSelectable(row) {
            const index = this.lockRows.findIndex((item) => {
                return item.code === row.code;
            });
            return index === -1;
        },
        show(row) {
            const _this = this;
            UrlUtils.GoodsBrands(_this, (data) => {
                _this.meta.brands = data;
            });
            UrlUtils.GroupedGoodsCategories(_this, (rst) => {
                _this.meta.categories = rst;
            });
            if (row) {
                this.form.type = row.type == 0 ? 0 : row.type || '';
                this.row = row;
            } else {
                this.row = {};
            }
            this.dialogFormVisible = true;
        },
        close() {
            Object.assign(this.$data, this.$options.data());
        },
    },
};
</script>

<style scoped>
.PickupSku .el-form-item {
    margin-bottom: 0;
}
</style>
